import $ from 'jquery';

$(document).find('a[href]:not([href="#"])').each(function (index, link) {
  const isBlankable =
    !link.href.match(/^(mailto|tel)\:/) &&                                                     // Ignore mailto and tel
    (link.href.match(/\.(pdf|xls|doc|ppt)/) ||                                                 // Enable if document
    (link.hostname != location.hostname && (!link.download || !link.target || !link.rel)));    // Enable if different domain with no download, target or rel attributes

  if (isBlankable && !link.target) {
    link.target = "_blank";
    link.rel = "noopener";
  }
});
